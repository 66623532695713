body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    
}





.thank-you-page{
  overflow: hidden;
}

.text-container{
   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 1;

  }
  
  .heart-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
  }
  
 .thank-you-text{
    z-index: 1;
    font-size: 3rem;
    font-weight: 600;
    color:#f13b51;
    text-align: center;
    
 }
 .thank-you-text-p{
    z-index: 1;
    font-size: 1.5rem;
    font-weight: 600;
    color: #4a5a67;
    text-align: center;
 }
