

.welcome-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 50px;
    margin-bottom: 10px;
    
  }
  
  .welcome-text span {
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 20px;
    display: inline-table;
    text-align: center;
    line-height: 50px;
    padding: 2rem;
    margin: 5px;
    opacity: 1;
    z-index: 1;
    
  }
  
  .welcome-text span::before,
  .welcome-text span::after {
    content: '';
    border-radius: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    opacity: 0;
    display: none;
  }
  
  @keyframes animateLoader {
    0% {
      transform: translateY(800px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    99% {
      transform: scale(5);
      opacity: 0;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
  
  .welcome-text span:nth-child(1) {
    background-color: red;
    animation: animateLoader 2s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(2) {
    background-color: orange;
    animation: animateLoader 2.1s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(3) {
    background-color: rgb(235, 183, 87);
    animation: animateLoader 2.2s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(4) {
    background-color: yellow;
    animation: animateLoader 2.3s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(5) {
    background-color: rgb(72, 184, 72);
    animation: animateLoader 2.4s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(6) {
    background-color: green;
    animation: animateLoader 2.5s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(7) {
    background-color: rgb(0, 166, 255);
    animation: animateLoader 2.6s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(8) {
    background-color: blue;
    animation: animateLoader 2.6s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(9) {
    background-color: rgb(192, 88, 192);
    animation: animateLoader 2.8s ease-in-out forwards;
  }
  
  .welcome-text span:nth-child(10) {
    background-color: purple;
    animation: animateLoader 2.9s ease-in-out forwards;
  }
  

  @media (max-width: 768px) {
    .welcome-text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;


    }
    .welcome-text span {
        margin: 2px;
        padding: 10px;
        border-radius: 10px;
    }
    
    .welcome-text span:nth-child(6){
        padding: 2px;
    }
  }

  canvas{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  



